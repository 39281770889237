<template>
  <div class="book-index">
    <div class="banner">
      <div class="thumb"></div>
      <div class="content">
        <div class="title">{{puInfo.jpmc || '-'}}</div>
        <div class="root">始祖：{{ puInfo.firstAncestor || '-' }}</div>
        <div class="date">修谱时间：{{puInfo.xpsj || '-'}}</div>
        <div class="btns">
          <div class="btn" @click="jumpToCepu">世系表</div>
          <div class="btn" @click="jumpToPreview">图文查看</div>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="info1">
        <div class="number">{{puInfo.peopleCount || '-'}}</div>
        <div class="desc">人数</div>
      </div>
      <!-- <div class="info1">
        <div class="number">-</div>
        <div class="desc">浏览次数</div>
      </div>
      <div class="info1">
        <div class="number">-</div>
        <div class="desc">点赞</div>
      </div> -->
    </div>
    <div class="bottom-info">
      <div class="header">
        族谱简介
      </div>
      <div class="cell">
        <div class="before">姓氏：</div>
        <div class="after">{{puInfo.jpxs || '-'}}</div>
      </div>
      <div class="cell">
        <div class="before">堂号：</div>
        <div class="after">{{puInfo.tanghao || '-'}}</div>
      </div>
      <div class="cell">
        <div class="before">世系名称：</div>
        <div class="after">{{puInfo.sxmc || '-'}}</div>
      </div>
      <div class="cell">
        <div class="before">发源地：</div>
        <div class="after">{{puInfo.fyd || '-'}}</div>
      </div>
      <div class="cell">
        <div class="before">修谱师：</div>
        <div class="after">{{puInfo.zhubian || '-'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import apis from '@/apis'
export default {
  name: "BookIndex",
  data() {
    return {
      puInfo: {},
      list: [{}],
      creatId: ''
    }
  },
  created() {
    this.creatId = this.$route.query.creatId; // 谱id
    this.getPuInfo()
  },
  methods: {
    getPuInfo() {
      if (!this.creatId) return
      apis.getPudata({ creatId: this.creatId }).then(data => {
        this.puInfo = data
      })
      apis.scrollListData({
        treeid: this.$route.query.treeid,
        genealogyid: this.$route.query.creatId,
        page: 1,
      }).then(data => {
        this.list = data.data || [{}]
      });
    },
    jumpToPreview() {
      this.$router.push({
        path: `/preview-data?genealogyId=${this.$route.query.creatId}&token=${this.$route.query.token}`
      })
    },
    jumpToCepu() {
      this.$router.push({
        path: `/book-scroll?creatId=${this.$route.query.creatId}&treeId=${this.$route.query.treeid}&token=${this.$route.query.token}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.book-index {
  min-height: 100vh;
  background-color: #F5F7FA;

  .banner {
    height: 371px;
    width: 750px;
    background-color: aliceblue;
    position: relative;
    background-image: url(https://img.mmjp5000.com/images/2022/11/12/22x.png);
    background-size: cover;

    .thumb {
      position: absolute;
      top: 95px;
      left: 24px;
      width: 212px;
      height: 310px;
      // background-color: azure;
      background-image: url(https://img.mmjp5000.com/images/2022/11/12/-12x.png);
      background-size: cover;
    }

    .content {
      color: #fff;
      font-size: 26px;
      position: absolute;
      top: 94px;
      left: 275px;

      .title {
        font-size: 33px;
        margin-bottom: 24px;
      }

      .root {
        margin-bottom: 17px;
      }

      .btns {
        margin-top: 100px;
        gap: 28px;
        display: flex;

        .btn {
          width: 211px;
          height: 67px;
          background: #E3A130;
          border-radius: 31px;

          font-size: 30px;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .center {
    display: flex;
    justify-content: center;
    height: 181px;
    padding-top: 67px;
    box-sizing: border-box;
    background-color: #fff;

    .info1 {
      width: 231px;
      height: 67px;
      text-align: center;
      border-right: 1px solid #CCCCCC;

      &:last-of-type {
        border-right: none;
      }
    }
  }

  .bottom-info {
    width: 702px;
    // height: 536px;
    box-sizing: border-box;
    padding: 30px;
    margin: 20px 24px;
    background-color: #fff;
    color: #666666;
    
    .header {
      width: 100%;
      font-size: 33px;
      padding-bottom: 30px;
      display: flex;
      align-items: center;

      &::before {
        content: '';
        display: block;
        width: 9px;
        height: 31px;
        background: #E3A130;
        margin-right: 24px;
      }
    }

    .cell {
      max-width: none;
      line-height: 85px;
      font-size: 30px;
      display: flex;
      justify-content: space-between;
      border-top: 2px solid #E0E0E0;
    }
  }
}
</style>